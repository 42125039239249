import { PATH_PREFIX, useHttp } from "./constants";

export class tgappApi {
  static http = useHttp(`${PATH_PREFIX.TG_APP}`);
  static async auth(init_data) {
    const { data } = await tgappApi.http.post(`/auth/`, {
      init_data:
        init_data ||
        "query_id=AAGzoy4YAAAAALOjLhh9yvii&user=%7B%22id%22%3A405709747%2C%22first_name%22%3A%22Maxim%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22subbnk%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2FGQMUWHgHiCFjte_7xlrwpvvCIbGjuibUsXI_RWyeGHY.svg%22%7D&auth_date=1734027121&signature=bVnDbjiUfMUDZ1wr12lFIhtZv3SzTfJ4Soe5O77c3-c11t4kUFdA8zf3VFsrUNqmpZIoiFsNw4AQFd5HUoBNAQ&hash=d3f0058bdbb4c4cb4600f41ed946eb6c2360c553267ec159e5cc079ae6a47a9b",
    });
    return data;
  }
}

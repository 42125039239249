// export function formatDate(dateString) {
//   const months = [
//     "января",
//     "февраля",
//     "марта",
//     "апреля",
//     "мая",
//     "июня",
//     "июля",
//     "августа",
//     "сентября",
//     "октября",
//     "ноября",
//     "декабря",
//   ];
//   const dateParts = dateString.split("-");
//   const year = dateParts[0];
//   const month = months[parseInt(dateParts[1], 10) - 1];
//   const day = Number(dateParts[2]);
//   return `${day} ${month} ${year} года`;
// }

export function formatDate(dateString) {
  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  if (dateString) {
    const dateParts = dateString.split("-");
    const year = dateParts[0];
    const month = months[parseInt(dateParts[1], 10) - 1];
    const day = Number(dateParts[2]);
    const currentYear = new Date().getFullYear(); // Формируем вывод
    return year == currentYear
      ? `${day} ${month}`
      : `${day} ${month} ${year} года`;
  }
}

import axios from "axios";

export const LS_ACCESS_TOKEN = "access_token";
export const LS_SHOP_ID = "shop_id";

const host = {
  stage: "staging.sashka.site",
  prod: "sashka.site",
};

export const PATH_PREFIX = {
  SHOPS: "shops",
  TG_APP: "tgapp",
};

export const backendPath = `https://${
  host[process.env.REACT_APP_BACKEND_HOST || "stage"]
}/api/shop_admin`;

export const useHttp = (prefix) => {
  const $api = axios.create({
    baseURL: `${backendPath}/${prefix}`,
    // withCredentials: true,
  });

  // Перехватываем запросы для добавления токена авторизации
  $api.interceptors.request.use(
    (config) => {
      const token = sessionStorage.getItem(LS_ACCESS_TOKEN);

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Перехватываем ответы для обработки ошибок
  $api.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      console.log(err);
      // const originalConfig = err.config;
      // if (originalConfig.url !== RoutePath.LOGIN && err.response) {
      //   if (err.response.status === 401 && !originalConfig._retry) {
      //     // console.log("refresh");
      //     // refresh expired
      //     originalConfig._retry = true; // Отмечаем, что повторный запрос будет выполнен

      //     const accessToken = await refreshToken();

      //     originalConfig.headers["Authorization"] = `Bearer ${accessToken}`;

      //     return $api(originalConfig);
      //   }
      // }

      // // Возвращаем отклоненное обещание с ошибкой
      // // window.location.href = '/';
      // return Promise.reject(err);
    }
  );
  return $api;
};

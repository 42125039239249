import React, { useCallback, useEffect, useRef, useState } from "react";
import s from "./Chat.module.css";
import moment from "moment";
import { formatDate } from "../../utils/formatDate";
import XsLoader from "../../shared/assets/icons/xsLoader";
import ChevronDown from "../../shared/assets/icons/chevron-down";
import { shopsApi } from "../../requests/shopsApi";
import Trash03 from "../../shared/assets/icons/trash-03";
const Chat = ({
  shopId,
  data,
  setData,
  shopInfo,
  getDatesPortion,
  isGettingMorePrevDates,
  setIsGettingMorePrevDates,
  isGettingMoreNextDates,
  setIsGettingMoreNextDates,
  dateDayDiapason,
  loading,
  getNextDates,
  hasMoreFutureDates,
  hasMorePrevDates,
}) => {
  const [deleteOpenId, setDeleteOpenId] = useState(null);
  // const [adding, setAdding] = useState([])
  const chatRef = useRef(null);
  const currentDateRef = useRef(null);
  const lastPrevDateRef = useRef(null);
  const lastFutureDateRef = useRef(null);

  const dates = Object.values(data).sort((a, b) =>
    moment(a.date).isAfter(b.date) ? 1 : -1
  );
  const datesArrLength = Object.keys(data).length;

  const today = moment();
  const todayIndex = dateDayDiapason / 2;
  const lastFutureDateObserverOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  };
  console.log(data);

  const addOnDate = useCallback((date) => {
    setData((prev) => ({
      ...prev,
      [date]: {
        ...prev[date],
        isOnAddMode: true,
        addData: {
          categoryId: 1,
          productId: 1,
          value: undefined,
        },
      },
    }));
  }, []);

  const setAddInfo = useCallback(({ date, addData }) => {
    setData((prev) => ({
      ...prev,
      [date]: {
        ...prev[date],
        addData: {
          ...prev[date].addData,
          ...addData,
        },
      },
    }));
  }, []);

  const cancelOnDate = useCallback((date, newDateNodes) => {
    setData((prev) => ({
      ...prev,
      [date]: {
        ...prev[date],
        isOnAddMode: false,
        addData: undefined,
        info: newDateNodes,
      },
    }));
  }, []);

  const saveOnDate = useCallback(
    async (date) => {
      const newDateNodes = await shopsApi.addShopNode(
        shopId,
        date,
        data[date].addData
      );
      cancelOnDate(date, newDateNodes.info);
    },
    [cancelOnDate, data]
  );
  const getMoreNextDates = async (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting && hasMoreFutureDates && !isGettingMoreNextDates) {
      setIsGettingMoreNextDates(true);
      getNextDates();
    }
  };

  const getMorePrevDates = async (entries) => {
    const [entry] = entries;
    if (
      entry.isIntersecting &&
      hasMorePrevDates &&
      // !datesArrLength &&
      !isGettingMorePrevDates &&
      !loading
      // + ориентироваться на положение скролла?
    ) {
      setIsGettingMorePrevDates(true);
      getDatesPortion();
    }
  };

  const deleteNote = async (noteId, date) => {
    setData((prev) => ({
      ...prev,
      [date]: {
        ...prev[date],
        info: prev[date].info.filter((note) => note.id !== noteId),
      },
    }));
    shopsApi.deleteShopNode(noteId, shopId);
  };
  // загрузка предыдущих на скролл вверх
  // const ref = useRef();
  // useEffect(() => {
  //   // Начальная загрузка сообщений
  //   // loadMessages(); // Добавляем обработчик скролла
  //   const handleScroll = async (event) => {
  //     // Проверяем, достиг ли пользователь верхней части списка сообщений
  //     if (event.target.scrollTop === 0 && !isGettingMorePrevDates) {
  //       await getDatesPortion();
  //       if (ref.current) setTimeout(() => ref.current.scrollIntoView(), 0);
  //     }
  //   }; // Ссылку на контейнер сообщений
  //   const messageContainer = chatRef.current;
  //   messageContainer.addEventListener("scroll", handleScroll);
  //   return () => {
  //     messageContainer.removeEventListener("scroll", handleScroll);
  //   };
  // }, [isGettingMorePrevDates]);

  // todo автоматическая прокрутка при добавлении элемента старой даты чтобы не происходил баг постоянной подгрузки новых дат
  useEffect(() => {
    if (chatRef.current && datesArrLength) {
      chatRef.current.scrollBy({
        top: 100, //100 - при быстрой прокрутке и 1 при медленной
      });
    }
  }, [chatRef.current, datesArrLength]);

  // Загрузка предыдущих дат при достижении самого первого элемента прошлых дат
  useEffect(() => {
    const observer = new IntersectionObserver(getMorePrevDates);
    if (
      lastPrevDateRef.current &&
      hasMorePrevDates &&
      !isGettingMorePrevDates
    ) {
      observer.observe(lastPrevDateRef.current);
    }
    return () => {
      if (lastPrevDateRef.current) {
        observer.unobserve(lastPrevDateRef.current);
      }
    };
  }, [
    hasMorePrevDates,
    isGettingMorePrevDates,
    // datesArrLength,
    lastPrevDateRef.current,
    loading,
  ]);

  // Прокрутка к элементу с текущей датой при загрузке страницы
  useEffect(() => {
    if (
      currentDateRef.current &&
      datesArrLength &&
      datesArrLength <= dateDayDiapason + 1
    ) {
      setTimeout(() => currentDateRef.current.scrollIntoView(), 10);
    }
  }, [currentDateRef.current, datesArrLength]);

  // Получение будщих дат при достижении самого нижнего элемента даты
  useEffect(() => {
    const observer = new IntersectionObserver(
      getMoreNextDates,
      lastFutureDateObserverOptions
    );
    const currentLastDate = lastFutureDateRef.current;
    if (currentLastDate) observer.observe(currentLastDate);

    return () => {
      if (currentLastDate) observer.unobserve(currentLastDate);
    };
  }, [hasMoreFutureDates, isGettingMoreNextDates, lastFutureDateRef.current]);
  // console.log(isGettingMoreNextDates);

  useEffect(() => {
    const handleClickOutside = (e) => {
      console.log(e.target.id, e.target.parentNode.id);
      const isNotDeletingId =
        e.target.id !== deleteOpenId &&
        e.target.parentNode?.id !== deleteOpenId;

      if (
        isNotDeletingId &&
        !e.target.closest("#trash-button") &&
        e.target.id !== "trash-button"
      ) {
        setDeleteOpenId(null);
      }
    };
    chatRef.current?.addEventListener("click", handleClickOutside);
    return () =>
      chatRef.chatRef?.removeEventListener("click", handleClickOutside);
  }, []);
  // if (!datesArrLength) {
  //   return <Preloader />;
  // }
  return (
    <div className={s.chatContainer} ref={chatRef}>
      <div
        ref={!datesArrLength ? lastPrevDateRef : null}
        style={{ height: "20px" }}
      ></div>
      {isGettingMorePrevDates && (
        <div className={s.loaderContainer}>
          <XsLoader />
        </div>
      )}
      <div>
        {/* <div ref={lastPrevDateRef} style={{ height: "20px" }}></div> */}
        {dates.map(({ date, info, isOnAddMode, addData }, index) => {
          const isAddBtn = moment(date)?.isSameOrAfter(today.startOf("day"));
          return (
            <>
              <div
                className={s.dateContainer}
                key={date}
                ref={
                  index === dates.length - 1
                    ? lastFutureDateRef
                    : todayIndex === index
                    ? currentDateRef
                    : // :
                    index === 0
                    ? lastPrevDateRef
                    : // index === 30
                      // ? ref
                      null
                }
              >
                <div className={s.date}>{formatDate(date)}</div>
                {info?.map(({ id, category_id, product_id, value }) => (
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "auto",
                      alignItems: "center",
                      gap: "6px",
                      position: "relative",
                    }}
                    onClick={() => setDeleteOpenId(id)}
                    id={id}
                  >
                    <div className={s.infoCard} key={id}>
                      {`${shopInfo.categories.reduce(
                        (s, { id, name }) => (id === category_id ? name : s),
                        "_"
                      )}
                       | ${shopInfo.products.reduce(
                         (s, { id, name }) => (id === product_id ? name : s),
                         "_"
                       )}
                       | ${value || "_"}`}
                    </div>
                    {deleteOpenId === id && (
                      <div
                        style={{ padding: "4px" }}
                        type="role"
                        onClick={() => deleteNote(id, date)}
                        id="trash-button"
                      >
                        <Trash03 />
                      </div>
                    )}
                  </div>
                ))}
                {isAddBtn ? (
                  isOnAddMode ? (
                    <div className={s.selectionBlock}>
                      <div className={s.selectionContainer}>
                        <div>Категория: </div>
                        <div className={s.selectContainer}>
                          <select
                            className={s.select}
                            value={addData.categoryId}
                            onChange={(e) => {
                              setAddInfo({
                                date,
                                addData: { categoryId: e.target.value },
                              });
                            }}
                          >
                            {(shopInfo?.categories || []).map(
                              ({ id, name }) => (
                                <option value={id} key={id}>
                                  {name}
                                </option>
                              )
                            )}
                          </select>
                          <ChevronDown />
                        </div>
                      </div>

                      <div className={s.selectionContainer}>
                        <div>Тип товара: </div>
                        <div className={s.selectContainer}>
                          <select
                            className={s.select}
                            value={addData.productId}
                            onChange={(e) => {
                              setAddInfo({
                                date,
                                addData: { productId: e.target.value },
                              });
                            }}
                          >
                            {(shopInfo?.products || []).map(({ id, name }) => (
                              <option value={id} key={id}>
                                {name}
                              </option>
                            ))}
                          </select>
                          <ChevronDown />
                        </div>
                      </div>
                      {shopInfo?.categories.find(
                        ({ id }) => `${id}` === addData.categoryId
                      )?.is_require_value ? (
                        <>
                          <div>Значение: </div>
                          <input
                            className={s.input}
                            value={addData.value}
                            type="number"
                            step="1"
                            onChange={(e) => {
                              if (e.target.value >= 0) {
                                setAddInfo({
                                  date,
                                  addData: { value: e.target.value },
                                });
                              }
                            }}
                          />
                        </>
                      ) : null}

                      <div className={s.btnContainer}>
                        <button onClick={() => cancelOnDate(date)}>
                          Отменить
                        </button>
                        <button onClick={() => saveOnDate(date)}>
                          Сохранить
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      className={s.addBtn}
                      onClick={() => addOnDate(date)}
                    >
                      + Добавить
                    </button>
                  )
                ) : (
                  "Нет данных"
                )}
              </div>
            </>
          );
        })}
      </div>
      {isGettingMoreNextDates && (
        <div className={s.loaderContainer}>
          <XsLoader />
        </div>
      )}
    </div>
  );
};

export default Chat;

import { LS_SHOP_ID, PATH_PREFIX, useHttp } from "./constants";

// const shopId = sessionStorage.getItem(LS_SHOP_ID);
export class shopsApi {
  static http = useHttp(`${PATH_PREFIX.SHOPS}`);
  static async getAllShops() {
    const { data } = await shopsApi.http.get(``);
    return data;
  }

  static async addShopNode(shopId, date, { categoryId, productId, value }) {
    const { data } = await shopsApi.http.post(
      `/${shopId}/notes/`,
      {
        info_date: date,
        category_id: categoryId,
        product_id: productId,
        value: value,
      },
      {
        "Content-Type": "application/json; charset=UTF-8",
      }
    );
    return data;
  }
  static async deleteShopNode(noteId, shopId) {
    const { data } = await shopsApi.http.delete(`/${shopId}/notes/${noteId}`, {
      "Content-Type": "application/json; charset=UTF-8",
    });
    return data;
  }

  static async getShopCalendar(shopId, from, to) {
    const { data } = await shopsApi.http.get(
      `/${shopId}/calendar/?date_from=${from}&date_to=${to}`
    );
    return data;
  }
  static async getShopCategories(shopId) {
    const { data } = await shopsApi.http.get(`/${shopId}/categories`);
    return data;
  }
  static async getShopDetailInfo(shopId) {
    const { data } = await shopsApi.http.get(`/${shopId}`);
    return data;
  }
  static async getShopProducts(shopId) {
    const { data } = await shopsApi.http.get(`/${shopId}/products`);
    return data;
  }
}

import s from "./Preloader.module.css";
import loader from "../../../shared/assets/icons/loader.svg";
import { memo } from "react";
import React, { useEffect, useState, useRef } from "react";
const Preloader = memo(({ isContentPreloader = false }) => (
  <div className={isContentPreloader ? s.wrapper : s.fullWrapper}>
    <div className={s.preloaderContainer}>
      <img src={loader} alt="preloader" className={s.preloader} />
    </div>
  </div>
));
export default Preloader;

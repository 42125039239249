import { useEffect, useRef, useState } from "react";
import { useTelegram } from "./hooks/useTelegram";
import moment from "moment";
import Chat from "./components/Chat/Chat";
import Preloader from "./shared/ui/Preloader/Preloader";
import { LS_ACCESS_TOKEN, LS_SHOP_ID } from "./requests/constants";
import { shopsApi } from "./requests/shopsApi";
import { tgappApi } from "./requests/thApi";

const dateDayDiapason = 30;
const halfOfDiapason = Math.floor(dateDayDiapason / 2);
const dateFormat = "YYYY-MM-DD";

const prepareData = async (start, end, shopId) => {
  const newData = {};
  let currentDate = start.clone();
  try {
    while (currentDate.isSameOrBefore(end)) {
      const strDate = currentDate.format(dateFormat);
      newData[strDate] = {
        date: strDate,
      };
      currentDate = currentDate.add(1, "days");
    }
    const shopCalendar = await shopsApi.getShopCalendar(
      shopId,
      start.format(dateFormat),
      end.format(dateFormat)
    );
    for (const calendarInfo of shopCalendar) {
      newData[calendarInfo.date] = calendarInfo;
    }
    return newData;
  } catch (e) {
    throw new Error(e);
  }
};

function App() {
  const isInit = useRef(null);
  const { tg, user, queryId } = useTelegram();
  const [data, setData] = useState({});
  const [shopInfo, setShopInfo] = useState(null);
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();

  const [minFutureDate, setMinFutureDate] = useState();
  const [maxFutureDate, setMaxFutureDate] = useState();

  const [loading, setLoading] = useState(false);

  const [isGettingMorePrevDates, setIsGettingMorePrevDates] = useState(false);
  const [isGettingMoreNextDates, setIsGettingMoreNextDates] = useState(false);
  const [hasMoreFutureDates, setHasMoreFutureDates] = useState(true);
  const [hasMorePrevDates, setHasMorePrevDates] = useState(true);
  const [shopId, setShopId] = useState(null);
  const [token, setToken] = useState(null);
  useEffect(() => {
    // console.log("trey set tg.ready() ...", Boolean(tg));
    tg?.ready();
    // console.log("trey set tg.ready() ... DONE");
    //дисейбл линт на зависимости т.к. нужен только один раз запуск
    // eslint-disable-next-line react-hooks/exhaustive-deps
    console.log(tg?.initData);
    (async () => {
      const data = await tgappApi.auth(tg?.initData);
      setShopId(data.shop_id);
      sessionStorage.setItem(LS_SHOP_ID, data.shopId);
      sessionStorage.setItem(LS_ACCESS_TOKEN, data.token);
      setToken(data.token);

      // console.log(data);
    })();
  }, []);
  const initialDate = moment();

  useEffect(() => {
    // console.log(isInit.current);
    // if (!isInit.current) {
    //   isInit.current = true;
    (async () => {
      // const shopId = sessionStorage.setItem(LS_SHOP_ID);
      if (shopId) {
        setLoading(true);
        const shopDetailInfo = await shopsApi.getShopDetailInfo(shopId);
        setShopInfo(shopDetailInfo);

        const minDate = initialDate.clone().subtract(halfOfDiapason, "days");
        const maxDate = initialDate.clone().add(halfOfDiapason, "days");
        setMinDate(minDate);
        setMaxDate(maxDate);
        setMinFutureDate(initialDate.clone().add(halfOfDiapason + 1, "days"));
        setMaxFutureDate(initialDate.clone().add(dateDayDiapason, "days"));
        await getDates(minDate, maxDate, "prev", shopId);

        setLoading(false);
      }
    })();

    // (async () => {
    //   const allShops = await getAllShops()
    //   console.log("🚀 ~ useEffect ~ allShops:", allShops)

    //   const shop = allShops[0]

    //   const shopDetailInfo = await getShopDetailInfo(shop.id)
    //   console.log("🚀 ~ useEffect ~ shopDetailInfo:", shopDetailInfo)

    //   const shopCalendar = await getShopCalendar(shop.id, '2024-09-10', '2024-09-30')
    //   console.log("🚀 ~ useEffect ~ shopCalendar:", shopCalendar)

    //   const shopNotes = await addShopNote(shop.id, '2024-09-08', {
    // categoryId: categories[0].id,
    //   productId: products[0].id,
    //   value: 54,
    // })
    //   console.log("🚀 ~ useEffect ~ addShopNote:", shopNotes)

    //   for( const shopNote of shopNotes.info) {
    //     if(shopNote?.id){
    //       const deleteShopNoteResult = await deleteShopNote(shopNote?.id, shop.id)
    //       console.log("🚀 ~ useEffect ~ deleteShopNoteResult:", deleteShopNoteResult)
    //     }
    //     console.log("🚀 ~ useEffect ~ deleteShopNote error :", shopNote)
    //   }

    // })()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId]);

  const getDates = async (minDate, maxDate, type = "prev", shopId) => {
    console.log(shopId);
    // console.log("try get " + type);
    if (type === "next") {
      setIsGettingMoreNextDates(true);
    } else {
      setIsGettingMorePrevDates(true);
    }
    try {
      const newData = await prepareData(minDate, maxDate, shopId);
      if (!newData?.length) {
        if (type === "next") setHasMoreFutureDates(false);
        else setHasMorePrevDates(false);
      }
      setData((prev) => ({ ...newData, ...prev }));
    } catch (error) {
      console.error("Ошибка при загрузке дат:", error);
    } finally {
      if (type === "next") {
        setIsGettingMoreNextDates(false);
      } else {
        setIsGettingMorePrevDates(false);
      }
    }
  };

  const handlePrevDates = async (minDate, maxDate, shopId) => {
    const newMaxDate = minDate?.clone();
    const newMinDate = maxDate?.clone().subtract(dateDayDiapason / 2, "days");
    setMinDate(newMinDate);
    setMaxDate(newMaxDate);
    await getDates(newMinDate, newMaxDate, "prev", shopId);
  };

  const handleNextDates = async (minFutureDate, maxFutureDate, shopId) => {
    const newMinDate = minFutureDate?.clone().add(0, "days");
    const newMaxDate = maxFutureDate?.clone().add(dateDayDiapason / 2, "days");
    setMinDate(newMinDate);
    setMaxDate(newMaxDate);
    await getDates(newMinDate, newMaxDate, "next", shopId);
  };
  if (loading) {
    return <Preloader />;
  }
  return (
    <div className="App">
      {/* <div style={{ padding: "10px" }}>tg: {JSON.stringify(tg)}</div>
      <div style={{ padding: "10px" }}>
        tg.initDataUnsafe: {JSON.stringify(tg?.initDataUnsafe)}
      </div>
      <div style={{ padding: "10px" }}>user: {JSON.stringify(user)}</div>
      <div style={{ padding: "10px" }}>queryId: {queryId}</div>
      <div style={{ padding: "10px" }}>
        tg.WebApp.initData: {JSON.stringify(tg?.WebApp?.initData)}
      </div> */}
      <header
        style={{
          background: "#7A7D7C",
          padding: "24px",
          color: "white",
          height: "8vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <h2>{shopInfo?.name}</h2>
      </header>

      <Chat
        shopId={shopId}
        data={data}
        setData={setData}
        shopInfo={shopInfo}
        getDatesPortion={() => handlePrevDates(minDate, maxDate, shopId)}
        isGettingMorePrevDates={isGettingMorePrevDates}
        setIsGettingMorePrevDates={setIsGettingMorePrevDates}
        isGettingMoreNextDates={isGettingMoreNextDates}
        setIsGettingMoreNextDates={setIsGettingMoreNextDates}
        loading={loading}
        getNextDates={() =>
          handleNextDates(minFutureDate, maxFutureDate, shopId)
        }
        dateDayDiapason={dateDayDiapason}
        hasMoreFutureDates={hasMoreFutureDates}
        hasMorePrevDates={hasMorePrevDates}
      />
    </div>
  );
}

export default App;
